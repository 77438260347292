@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Righteous&family=Bebas+Neue&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

html,
body,
#root {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    background: #FAFAFA;
    font-family: 'Open Sans', arial, sans-serif;
    font-weight: 400;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

#app {
    height: 100%;
    min-height: 0;
}

.home {
    padding: 46px 8px;
    padding-bottom: 16px;
    min-height: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #181c26;
}

.kevincontainer {
    flex: 1 0;
    row-gap: 12px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    min-height: 0;
}

.kevinbox {
    flex: 1 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.scroll-wrap {
    min-width: fit-content;
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 24px;
    margin-top: 12px;
    padding: 0 20px;
}

.profile {
    padding: 56px 20px;
    min-height: 100%;
    width: 100%;
}

.chat {
    width: fit-content;
}

.chatname {
    font-size: 14px;
    font-weight: 200;
    color: #ccc;
    font-family: 'Raleway', arial, sans-serif;
}

.chatcontent {
    // font-family: 'Raleway', arial, sans-serif;
    width: fit-content;
    background: #2f3641;
    color: #dcdcdc;
    border: 1px solid #6b7382;
    border-radius: 8px;
    margin-top: 4px;
    padding: 10px 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    max-width: 500px;
    word-break: break-word;

    h3 {
        margin-block-end: 8px;
        margin-block-start: 32px;
    }

    p {
        padding: 0;

        &:first-child {
            margin-block-start: 0;
        }

        &:last-child {
            margin-block-end: 0;
        }
    }


}

.aborted {
    font-size: 12px;
    margin-top: 4px;
    margin-left: 4px;
    color: #f66;
    opacity: 0.8;
}

.chat-assistant {
    .chatcontent {
        margin-left: 0;
        margin-right: -8px;
    }
}

.chat-user {
    align-self: flex-end;

    .chatname {
        text-align: right;
    }

    .chatcontent {}
}


.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #0d1016;
    border-bottom: 1px solid rgb(85, 85, 85);
    z-index: 50;

    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: 0px 18px;

    .logo {
        user-select: none;
        float: left;
        margin: 0;
        font-size: 42px;
        font-weight: 100;
        color: #FFF;
        font-family: 'Righteous', arial, sans-serif;
        margin-right: -2px;

        .logo-icon {
            font-weight: 900;
            color: #fc6;
            margin-right: 2px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 12px;
            width: 32px;
            height: 35px;
            border-top: 3px solid #fc6;
            border-left: 3px solid #fc6;
            border-radius: 0px;
            z-index: -1;
        }
    }

    .subtitle {
        float: left;
        margin: 0;
        padding: 0 15px;
        font-size: 18px;
        color: #c5c5c5;
        font-family: 'Bebas Neue', arial, sans-serif;
    }
}

.header nav {
    float: right;
    font-size: 100%;
}

.header nav a {
    display: inline-block;
    height: 56px;
    line-height: 56px;
    padding: 0 15px;
    min-width: 50px;
    text-align: center;
    background: rgba(255, 255, 255, 0);
    text-decoration: none;
    color: #FFF;
    will-change: background-color;
}

.header nav a:hover,
.header nav a:active {
    background: rgba(0, 0, 0, 0.2);
}

.header nav a.active {
    background: rgba(0, 0, 0, 0.4);
}

ol {
    margin: 0;
    padding-left: 20px;
}

.command {
    flex: 0 1;
    padding: 0 20px;
    min-height: 40px;
    width: 100%;
    overflow: hidden;
    min-height: 60px;
    display: flex;
    height: 100%;

    form {
        display: flex;
        flex: 1;
        min-height: 100%;
    }
}

.kevinput {
    border: none;
    outline: none;
    font-size: 16px;
    padding-left: 8px;
    height: 100%;
    width: 100%;
    font-weight: 200;
    border: 1px solid rgb(35, 35, 35);
    background-color: #262b34;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
    color: #cccac3;
    display: flex;
    flex: 1;
}


.sponsor {
    color: gray;
    font-size: 14px;
    margin-top: 6px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;

    .roleplay {
        flex: 1 1;

        .role {
            margin-left: 8px;
        }
    }

    .models {
        flex: auto 0;
        align-self: flex-end;

        a {
            color: #fc6;
        }

        .model-name a {
            color: #777;
        }

        .model-name.active-model a {
            color: #fc6;
        }
    }
}